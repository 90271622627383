<template>
    <div class="flex gap-5 relative">
        <slot/>
        <UIIcon name="front/copy" class="w-5 h-5 cursor-pointer active:text-blue-700" @click.prevent="onClick"/>
        <span v-if="copied" class="text-xs text-blue-700 absolute right-0 -top-5 translate-x-1/4" >Copy!</span>
    </div>
</template>

<script setup lang="ts">
const {copy} = defineProps(['copy']);
const copied = ref(false);

const onClick = () => {
    navigator.clipboard.writeText(copy);

    copied.value = true;

    setTimeout(() => {
        copied.value = false;
    }, 2000)
}
</script>
