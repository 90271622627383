<template>
    <div class="text-slate-800 hidden lg:grid gap-7.5">
        <div>
            <div class="text-xl font-bold flex items-center gap-1.5 mb-1.5">
                <UIIcon name="front/heroicons-solid/map-pin" class="w-5 h-5"/>
                <span>Address:</span>
            </div>

            <CopyPaste :copy="settings.mainAddress">
                <address class="not-italic text-lg">{{settings.mainAddress}}</address>
            </CopyPaste>
        </div>

        <div>
            <div class="text-xl font-bold flex items-center gap-1.5 mb-1.5">
                <UIIcon name="front/heroicons-solid/phone" class="w-5 h-5"/>
                <span>Contact Number:</span>
            </div>

            <ULink class="font-medium" to="tel:+1 (123) 456-7890">{{settings.mainPhone}}</ULink>
        </div>

        <div>
            <div class="text-xl font-bold flex items-center gap-1.5 mb-1.5">
                <UIIcon name="front/heroicons-solid/clock" class="w-5 h-5"/>
                <span>Business Hours:</span>
            </div>

            <div class="font-medium">
                <div v-for="item of settings.businessHours">{{item}}</div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import CopyPaste from '~/components/UI/CopyPaste.vue';

const settings = await useSettings(['mainAddress', 'mainPhone', 'businessHours']);
</script>
